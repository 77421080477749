<template>
  <b-form
    class="reporter-schedule"
    @submit.prevent="save"
  >
    <page-head-actions :title="name">
      <template v-slot:buttons>
        <b-button
          type="submit"
          variant="primary"
          class="btn-round pull-right"
        >{{t('save')}}</b-button>
        <b-button
          type="button"
          variant="primary"
          class="btn-round pull-right"
          @click="cancel"
        >{{t('cancel')}}</b-button>
      </template>
    </page-head-actions>

    <b-row>
      <b-col cols="12" md="7">
        <b-card>
          <template v-slot:header>
            <h4>
              <i class="tim-icons icon-watch-time"></i>
              <span>{{t('Programmed report')}}</span>
            </h4>
          </template>

          <b-row>
            <b-col cols="12">
              <b-form-group
                class="form-group-select"
                :label="t('First execution date:')"
              >
                <datetime
                  v-model="firstExecution"
                  input-class="form-control"
                  format="yyyy-MM-dd HH:mm"
                  v-validate="validations.executionDate"
                  data-vv-as=" "
                  type="datetime"
                  class="theme-orange"
                  :phrases="{
                    ok: this.t('accept'),
                    cancel: this.t('cancel'),
                  }"
                  :minute-step="5"
                  :min-datetime="new Date().toISOString()"
                  zone="Local"
                ></datetime>
                <div v-if="!isValid && !firstExecution" class="text-danger invalid-feedback" style="display: block; white-space: normal; line-height: normal;">
                    {{t('required_field')}}
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                class="form-group-select"
                :label="t('Perform this task:')"
              >
                <b-row>
                  <b-col :cols="['weekly', 'monthly'].includes(frequency) ? '7' : '12' ">
                    <el-select
                      class="select-primary"
                      size="large"
                      placeholder="----"
                      v-model="frequency"
                    >
                      <el-option
                        v-for="(option, index) in frequencyOptions"
                        class="select-primary"
                        :value="option.value"
                        :label="t(option.label)"
                        :key="`report-from-option_${index}`"
                      ></el-option>
                    </el-select>
                  </b-col>
                  <b-col
                    cols="5"
                    v-if="frequency === 'weekly'"
                  >
                    <el-select
                      class="select-primary"
                      size="large"
                      placeholder="Day"
                      v-model="weekDay"
                    >
                      <el-option
                        v-for="(option, index) in weekDayOptions"
                        class="select-primary"
                        :value="option.value"
                        :label="t(option.label)"
                        :key="`report-from-option_${index}`"
                      >
                      </el-option>
                    </el-select>
                  </b-col>
                  <b-col
                    cols="5"
                    v-if="frequency === 'monthly'"
                  >
                    <el-select
                      class="select-primary"
                      size="large"
                      placeholder="Day"
                      v-model="monthDay"
                    >
                      <el-option
                        v-for="(option, index) in monthDayOptions"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="`report-from-option_${index}`"
                      >
                      </el-option>
                    </el-select>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="5">
              <b-form-group
                class="form-group-select"
                :label="t('Send information to:')"
              >
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="----"
                  v-model="sendInfo"
                >
                  <el-option
                    v-for="(option, index) in sendReportByOptions"
                    class="select-primary"
                    :value="option.value"
                    :label="t(option.label)"
                    :key="`report-from-option_${index}`"
                  >
                  </el-option>
                </el-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="7">
              <fg-input
                :label="t('Sender:')"
                type="email"
                v-model="sender"
                name="email"
                v-validate="{ required: true, min:5, regex: emailRegEx }"
                :error="this.errors.first('email')"
              ></fg-input>
            </b-col>
            <b-col cols="12">
              <b-form-group
                class="form-group-select"
                :label="t('Addresses:')"
              >
                <b-form-tags
                  add-button-variant="primary"
                  :add-button-text="t('Add')"
                  tag-variant="light"
                  :duplicate-tag-text="t('Duplicate email')"
                  :invalid-tag-text="t('Invalid email')"
                  :tag-validator="emailValidator"
                  :placeholder="t('Add entry...')"
                  v-model="addresses"
                ></b-form-tags>
                <div v-if="!hasEmail" class="text-danger invalid-feedback" style="display: block; white-space: normal; line-height: normal;">
                    {{t('Address field is required')}}
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            class="btn-round pull-right margin-top-button"
            type="button"
            variant="primary"
            :disabled="!$store.getters['reporter/isReporterTemplateScheduled']"
            @click.prevent="removeSchedule"
          >{{t('Remove Schedule')}}</b-button>
        </b-card>
      </b-col>
      <b-col cols="12" md="5">
        <b-card>
          <template v-slot:header>
            <h4>
              <i class="tim-icons icon-watch-time"></i>
              <span>{{t('Executed instances')}}</span>
            </h4>
          </template>
          <fg-input
            :label="t('Next execution date:')"
            :value="nextExecution"
            disabled
          >
          </fg-input>

          <fg-input
            :label="t('Last successful execution:')"
            :value="lastExecution"
            disabled
          >
          </fg-input>
          <fg-input
            :label="t('Number of executions:')"
            :value="numberOfExecutions"
            disabled
          >
          </fg-input>
          <b-form-group
            class="form-group-select"
            :label="t('Executed instances:')"
          >
            <b-row>
              <b-col cols="12">
                <el-select
                  class="select-primary"
                  size="large"
                  :placeholder="t('Choose an entry')"
                  v-model="entryToView"
                >
                  <el-option
                    v-for="(option, index) in executedInstancesOptions"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="`executed-instances-option_${index}`"
                  >
                  </el-option>
                </el-select>
              </b-col>
            </b-row>
              <b-button
                class="btn-round margin-top-button executed-view-btn"
                type="button"
                variant="primary"
                @click.prevent="viewExecutedReport"
                :disabled="!entryToView"
              >
                {{t('view')}}
              </b-button>
        </b-form-group>
        </b-card>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
import PageHeadActions from '@/components/PageHeadActions'
import moment from 'moment';
import { Select, Option } from 'element-ui';
import { Datetime } from 'vue-datetime';
import { DateTime as objDateTime} from 'luxon';

export default {
  name: 'ReporterSchedule',
  components:{
    PageHeadActions,
    Datetime,
    objDateTime,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    templateId: {
      type: String,
      required: true,
    },
  },
  data(){
    return{
      isValid: true,
      firstExecution: '',
      frequency: '',
      weekDay: '',
      monthDay: '',
      sendInfo: '',
      sender: '',
      addresses: [],
      entryToView: '',
      frequencyOptions: [
        { label: 'Once', value: 'once' },
        { label: 'Daily', value: 'daily' },
        { label: 'Weekly', value: 'weekly' },
        { label: 'Monthly', value: 'monthly' },
      ],
      weekDayOptions: [
        { label: 'Sunday', value: 'sunday' },
        { label: 'Monday', value: 'monday' },
        { label: 'Tuesday', value: 'tuesday' },
        { label: 'Wednesday', value: 'wednesday' },
        { label: 'Thursday', value: 'thursday' },
        { label: 'Friday', value: 'friday' },
        { label: 'Saturday', value: 'saturday' },
      ],
      sendReportByOptions: [
        { label: 'Mail', value: 'mail' },
      ],
      emailRegEx: /^\w+[\w\.\-]*@\w+([\.-]?\w+)*(\.[\w\-]{2,8})+$/,
      validations: {
        executionDate: {
          required: true,
        },
      },
    }
  },
  watch:{
    frequency(newFrequency, oldFrequency) {
      if (newFrequency === 'weekly' && !this.weekDay) {
        this.weekDay = this.weekDayOptions[0].value;
      } else if (newFrequency === 'monthly' && !this.monthDay) {
        this.monthDay = this.monthDayOptions[0].value;
      }
    },
  },
  computed:{
    hasEmail () {
      if (!this.isValid) {
        if (this.addresses && this.addresses.length === 0) {
          return false
        }
      }
      return true
    },
    name() {
      return this.$store.getters['reporter/adminReportDetails'].name
        || 'Unknown Report';
    },
    graph_style() {
      return this.$store.getters['reporter/adminReportDetails'].query.style
        || 'bar';
    },
    monthDayOptions(){
      return Array.from(Array(31).keys())
        .map((el, i) => ({ label: i + 1, value: i + 1 }));
    },
    adminReport(){
      return this.$store.getters['reporter/adminReportDetails']
    },
    executedInstancesOptions() {
      return this.$store.getters['reporter/adminReportExecutions'].instances
        .map((item => ({ label: item, value: item })));
    },
    adminReportExecutions() {
      return this.$store.getters['reporter/adminReportExecutions'];
    },
    nextExecution() {
      if (!this.adminReportExecutions.nextExecution) {
        return '';
      }      
      return moment(this.adminReportExecutions.nextExecution).local().format('YYYY-MM-DD HH:mm')
    },
    lastExecution() {
      if (!this.adminReportExecutions.lastExecution) {
        return '';
      }

      return moment(this.adminReportExecutions.lastExecution).local().format('YYYY-MM-DD HH:mm')      
    },
    numberOfExecutions() {
      return this.adminReportExecutions.instances.length;
    },
  },
  methods:{
    async save(){      
      this.isValid = await this.$validator.validate();
      if (!this.isValid || this.addresses.length === 0 || this.firstExecution.length === 0) {
        this.isValid = false
        return;
      }   
      this.$store.commit('settings/startLoading', true);

      await this.$store.dispatch('reporter/scheduleAdminReport', {
        programmed: true,
        programming: {
          first_execution: moment.utc(this.datetimeFromISO(this.firstExecution).toUTC().toString()).format('YYYY-MM-DDTHH:mm:ss[Z]'),
          frequency: this.frequency,
          ...(this.frequency === 'weekly' && { week_day: this.weekDay }),
          ...(this.frequency === 'monthly' && { month_day: this.monthDay }),
          send_info_mail: {
            sender: this.sender,
            addresses: this.addresses,
          },
          send_info: this.sendInfo,
        }
      });

      if (this.$store.getters['error']) {
        this.$store.commit('settings/finishLoading', true);
        return;
      }

      setTimeout(() => {
        this.$store.commit('settings/finishLoading', true);
        this.$router.push({ path: '/reporter/templates' });
        this.$notify({
          message: this.t('The report schedule has been set'),
          type: 'success',
          verticalAlign: 'bottom',
        });
      }, 1000);
    },
    cancel(){
      this.$router.push({ path: '/reporter/templates' });
    },
    async removeSchedule(){
      this.$store.commit('settings/startLoading', true);

      await this.$store.dispatch('reporter/scheduleAdminReport', {
        programmed: false,
        programming: {},
      });

      if (this.$store.getters['error']) {
        this.$store.commit('settings/finishLoading', true);
        return;
      }

      setTimeout(() => {
        this.$store.commit('settings/finishLoading', true);
        this.$router.push({ path: '/reporter/templates' });
        this.$notify({
          message: this.t('The report schedule has been removed'),
          type: 'success',
          verticalAlign: 'bottom',
        });
      }, 1000);
    },
    viewExecutedReport(){
      const query = {
        isHistoric: true,
        adminReportId: this.templateId,
        reportName: this.entryToView,
        name: this.entryToView,
        style: this.graph_style,
      };

      this.$router.push({ path: '/reporter/templates/view', query });
    },
    emailValidator(tag) {
      return tag.length > 4 && this.emailRegEx.test(tag)
    },
    frequencyInit() {
      this.frequency = this.frequencyOptions[0].value;
    },
    sendReportByInit() {
      this.sendInfo = this.sendReportByOptions[0].value;
    }
  },
  async mounted(){
    this.$store.commit('settings/startLoading', true);

    this.frequencyInit();
    this.sendReportByInit();

    await this.$store
      .dispatch('reporter/getAdminReportDetails', this.$route.params.id);

    if (this.$store.getters['reporter/isReporterTemplateScheduled']) {
      Object.assign(this, this.$store.getters['reporter/reporterTemplateSchedule']);
    }

    await this.$store
      .dispatch('reporter/getAdminReportExecutions', this.$route.params.id)

    this.$store.commit('settings/finishLoading', true);
  },
  destroyed() {
    this.$store.commit('reporter/RESET_ADMIN_REPORT_EXECUTIONS');
  },
}
</script>

<style lang="scss" scoped>
.executed-view-btn {
  min-width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.margin-top-button {
  margin-top: 29px;
}
</style>
