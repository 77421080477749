<template>
  <b-form
    class="reporter-templates-edit"
    @submit.prevent="save"
  >
    <page-head-actions
      v-model="name"
      name="name"
      ref="name"
      v-validate="validations.name"
      :error="errors.first('name')"
      :placeholder="t('Template Name')"
      editable
    >
      <template v-slot:buttons>
        <n-button
          type="primary"
          class="btn-round pull-right"
          @click.native="save"
          :visible_for_permissions='[{actions: ["PUT"], path: "/reports/.*"}]'
        >{{t('Save')}}</n-button>
        <n-button
          type="primary"
          class="btn-round pull-right"
          @click.native="$router.go(-1)"
        >{{t('Cancel')}}</n-button>
      </template>
    </page-head-actions>
    <div class="row">
      <div class="col-12 col-xl-7">
        <b-card>
          <template v-slot:header>
            <h4>
              <i class="tim-icons icon-notes"></i>
              <span>{{t('Report Configuration')}}</span>
            </h4>
          </template>
          <div class="row">
            <div class="col-12 col-md-6">
              <b-form-group
                class="form-group-select"
                :label="t('Report from')"
              >
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="----"
                  v-model="reportFrom"
                >
                  <el-option
                    v-for="(option, index) in reportFromOptions"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="`report-from-option_${index}`"
                  >
                  </el-option>
                </el-select>
              </b-form-group>
             <b-form-group
                class="form-group-select"
                :label="t('Grouped by')"
              >
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="----"
                  v-model="groupedBy"
                >
                  <el-option
                    v-for="(option, index) in groupedByOptions"
                    class="select-primary"
                    :value="option.value"
                    :label="t(option.label)"
                    :key="`report-from-option_${index}`"
                  >
                  </el-option>
                </el-select>
              </b-form-group>
              <b-form-group
                class="form-group-select"
                :label="t('Data computing')"
              >
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="----"
                  v-model="dataComputing"
                >
                  <el-option
                    v-for="(option, index) in dataComputingOptions"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="`report-from-option_${index}`"
                  >
                  </el-option>
                </el-select>
              </b-form-group>
              <b-form-group
                class="form-group-select"
                :label="t('Records to show')"
              >
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="----"
                  v-model="recordsToShow"
                >
                  <el-option
                    v-for="(option, index) in recordsToShowOptions"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="`report-from-option_${index}`"
                  >
                  </el-option>
                </el-select>
              </b-form-group>
              <b-form-group
                class="form-group-select"
                :label="t('Format')"
              >
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="----"
                  v-model="format"
                >
                  <el-option
                    v-for="(option, index) in formatOptions"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="`format-option_${index}`"
                  >
                  </el-option>
                </el-select>
              </b-form-group>
              <b-form-group
                class="form-group-textarea"
                :label="t('Report description')"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="description"
                  rows="3"
                  max-rows="3"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div class="col-12 col-md-6">
              <b-form-group
                class="form-group-select"
                :label="t('Type')"
              >
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="----"
                  v-model="type"
                >
                  <el-option
                    v-for="(option, index) in typeOptions"
                    class="select-primary"
                    :value="option.value"
                    :label="t(option.label)"
                    :key="`report-from-option_${index}`"
                  >
                  </el-option>
                </el-select>
              </b-form-group>
             <b-form-group
                class="form-group-select"
                :label="t('Subgrouped by')"
              >
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="----"
                  v-model="subgroupedBy"
                >
                  <el-option
                    v-for="(option, index) in subgroupedByOptions"
                    class="select-primary"
                    :value="option.value"
                    :label="t(option.label)"
                    :key="`report-from-option_${index}`"
                  >
                  </el-option>
                </el-select>
              </b-form-group>
              <b-form-group
                class="form-group-select"
                :label="t('Time frame')"
              >
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="----"
                  v-model="timeFrame"
                >
                  <el-option
                    v-for="(option, index) in timeFrameOptions"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="`report-from-option_${index}`"
                  >
                  </el-option>
                </el-select>
              </b-form-group>
              <b-form-group
                class="form-group-select"
                label="Start"
                v-if="timeFrame === 'custom'"
              >
                <datetime
                  v-model="start"
                  input-class="form-control"
                  format="yyyy-MM-dd HH:mm"
                  type="datetime"
                  class="theme-orange"
                  :minute-step="5"
                  :max-datetime="end"
                  :phrases="{
                    ok: this.t('accept'),
                    cancel: this.t('cancel'),
                  }"                  
                  :required="timeFrame === 'custom'"
                  zone="UTC"
                ></datetime>
              </b-form-group>
              <b-form-group
                class="form-group-select"
                label="End"
                v-if="timeFrame === 'custom'"
              >
                <datetime
                  v-model="end"
                  input-class="form-control"
                  format="yyyy-MM-dd HH:mm"
                  type="datetime"
                  class="theme-orange"
                  :minute-step="5"
                  :min-datetime="start"
                  :phrases="{
                    ok: this.t('accept'),
                    cancel: this.t('cancel'),
                  }"
                  :required="timeFrame === 'custom'"
                  zone="UTC"
                ></datetime>
              </b-form-group>
              <b-form-group
                class="form-group-switch"
              >
                <legend
                  class="bv-no-focus-ring col-form-label switch-label"
                >{{t('Classify results in descending order')}}</legend>
                <n-switch
                  v-model="classifyDescending"
                  :visible_for_permissions='[{actions: ["POST"], path: "/reports/.*"}]'
                />
              </b-form-group>
            </div>
          </div>
          <b-button
            type="button"
            variant="primary"
            class="btn-round pull-right"
            @click.prevent="view"
            :visible_for_permissions='[{actions: ["POST"], path: "/reports/.*"}]'
          >{{t('View')}}</b-button>
        </b-card>
      </div>
      <div class="col-12 col-xl-5">
        <b-card>
          <template v-slot:header>
            <h4>
              <i class="tim-icons icon-bullet-list-67"></i>
              <span>{{t('Selection Criteria')}}</span>
            </h4>
          </template>
          <div class="selection-criteria">
            <b-form-group
              class="selection-criteria-fg"
              v-for="(criteria, index) in selectionCriteria"
              :key="`selection-criteria-${index}`"
            >
              <legend
                class="bv-no-focus-ring col-form-label criteria-label"
              >{{ t(criteria.label) }}</legend>
              <template v-if="criteria.label === 'Category List'">
                <div class="selection-criteria-toggle">
                  <legend
                    class="bv-no-focus-ring col-form-label switch-label"
                  >{{t('Not Contains')}}</legend>
                  <n-switch
                    v-model="criteria.notContains"
                  />
                </div>
                <fg-input
                  :name="criteria.fieldName"
                  :ref="criteria.original"
                  v-model="criteria.value"
                  :error="errors.first('CategoryList')"
                  v-validate="validations.notList"
                ></fg-input>
              </template>
              <template v-else-if="criteria.fieldName === 'Module'">
                <div class="selection-criteria-toggle">
                  <legend v-if="criteria.fieldName"
                    class="bv-no-focus-ring col-form-label switch-label"
                  >{{t('Not Contains')}}</legend>
                  <n-switch
                    v-model="criteria.notContains"
                  />
                </div>
                <fg-input
                  :name="criteria.original"
                  :ref="criteria.original"
                  v-model="criteria.value"
                ></fg-input>
              </template>  
              <template v-else-if="criteria.fieldName === 'Application'">
                <div class="selection-criteria-toggle">
                  <legend v-if="criteria.fieldName"
                    class="bv-no-focus-ring col-form-label switch-label"
                  >{{t('Not Contains')}}</legend>
                  <n-switch
                    v-model="criteria.notContains"
                  />
                </div>
                <fg-input
                  :name="criteria.original"
                  :ref="criteria.original"
                  v-model="criteria.value"
                ></fg-input>
              </template>  
              <template v-else>
                <div class="selection-criteria-toggle">
                  <legend
                    class="bv-no-focus-ring col-form-label switch-label"
                  >{{t('Not in')}}</legend>
                  <n-switch
                    v-model="criteria.notIn"
                  />
                </div>
                <fg-input
                  :name="criteria.original"
                  :ref="criteria.original"
                  v-model="criteria.value"
                ></fg-input>
              </template>  
            </b-form-group>
          </div>
        </b-card>
      </div>
    </div>
  </b-form>
</template>

<script>
import { Select, Option } from 'element-ui';
import moment from 'moment-timezone';
import { Switch } from '@/components';
import { Datetime } from 'vue-datetime';
import { DateTime as objDateTime} from 'luxon';
import PageHeadActions from '@/components/PageHeadActions.vue';
import 'vue-datetime/dist/vue-datetime.css';

moment.tz.setDefault('UTC');

export default {
  name: 'ReporterTemplatesEdit',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    Datetime,
    objDateTime,
    PageHeadActions,
  },
  props: {
    templateId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      name: '',
      reportFrom: '',
      groupedBy: '',
      dataComputing: false,
      recordsToShow: '',
      format: 'bar',
      classifyDescending: false,
      type: '',
      subgroupedBy: '',
      timeFrame: '',
      start: moment().toISOString(),
      end: '',
      description: '',
      selectionCriteria: [],
      validations: {
        name: { required: true,
          max: 64,
          regex: /^[a-zA-Z0-9\\\\-\\\\._()@\s]+$/
         },
         notList: {
           regex: /^[a-zA-Z0-9\s]+$/
         }
      },
      fixedReportFrom: '',
      fixedSelectionCriteria: [],
    };
  },
  computed: {
    reportFromOptions() {
      return this.$store.getters['reporter/reportOptions'].map((option) => ({
        label: this.t(option.label),
        value: option.original,
      }));
    },
    currentOptionsSet() {
      const parent = this.$store.getters['reporter/reportOptions']
        .find((item) => item.original === this.reportFrom);

      const emptyOptionSet = {
        grouping: [],
        monitorSelecting: [],
        selecting: [],
        showing: [],
      };

      return parent ? parent.options : emptyOptionSet;
    },
    groupedByOptions() {
      return this.currentOptionsSet.grouping.reduce((accumulator, opt) => {
        if (
          opt.value === this.subgroupedBy ||
          (!this.$store.getters['settings/isIOT'] && (opt.value === 'UserName' || opt.value === 'UserGroups'))
        ) {
          return accumulator;
        }

        //  If the time-frame selected is Monthly, the option grouped by Hours is not available.
        if (opt.value === 'Hours' && this.timeFrame === 'lastmonth') {
          return accumulator;
        }

        if (!this.$store.getters['settings/isIOT'] && opt.value === 'ClientId') {
          opt.label = 'Device';
        }
        if ( this.reportFrom === "Web AntiBotnet" && opt.value === 'VirusName'){
          opt.label = 'Threat'
        }

        return [...accumulator, opt];
      }, []);
    },
    subgroupedByOptions() {
      const options = this.currentOptionsSet.grouping
        .reduce((accumulator, opt) => {
          if (
            opt.value === this.groupedBy ||
            (!this.$store.getters['settings/isIOT'] && (opt.value === 'UserName' || opt.value === 'UserGroups'))
          ) {
            return accumulator;
          }

          //  If the time-frame selected is Monthly, the option grouped by Hours is not available.
          if (opt.value === 'Hours' && this.timeFrame === 'lastmonth') {
            return accumulator;
          }

          if (!this.$store.getters['settings/isIOT'] && opt.value === 'ClientId') {
            opt.label = 'Device';
          }
          if ( this.reportFrom === "Web AntiBotnet" && opt.value === 'VirusName'){
            opt.label = 'Threat'
          }

          return [...accumulator, opt];
        }, []);

      options.splice(0, 0, { label: '----', value: '' });

      return options;
    },
    dataComputingOptions() {
      return [
        { label: this.t('Totals'), value: false },
        { label: this.t('Ratio'), value: true },
      ];
    },
    recordsToShowOptions() {
      return [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
        { label: '200', value: 200 },
        { label: '500', value: 500 },
        { label: '1000', value: 1000 },
        { label: '2000', value: 2000 },
        { label: '5000', value: 5000 },
        { label: '10000', value: 10000 },
        { label: '15000', value: 15000 },
      ];
    },
    typeOptions() {
      return this.currentOptionsSet.showing;
    },
    timeFrameOptions() {
      return [
        { label: this.t('Today'), value: 'today' },
        { label: this.t('Yesterday'), value: 'yesterday' },
        { label: this.t('Last Week'), value: 'lastweek' },
        { label: this.t('Last Month'), value: 'lastmonth' },
        { label: this.t('Custom'), value: 'custom' },
      ];
    },
    formatOptions() {
      return [
        { label: this.t('Bar chart'), value: 'bar' },
        { label: this.t('Line chart'), value: 'line' },
        { label: this.t('Pie chart'), value: 'pie' },
        { label: this.t('Table'), value: 'table' },
      ];
    },
    query() {
      return {
        name: this.name,
        GUIModeId: this.reportFrom,
        type: this.type,
        group: [
          this.groupedBy,
          ...(this.subgroupedBy ? [this.subgroupedBy] : []),
        ],
        limit: parseInt(this.recordsToShow, 10),
        order: this.classifyDescending,
        style: this.format,
        date_interval: this.timeFrame === 'custom'
        ? {
          start_date: moment(this.start)
            .format('YYYY-MM-DDTHH:mm:ss[Z]'),
          end_date: moment(this.end)
            .format('YYYY-MM-DDTHH:mm:ss[Z]'),
        } : this.timeFrame,
        where: this.selectionCriteria.reduce((accumulator, currentItem) => {
          if (currentItem.value) {
              return [...accumulator, {
                field: currentItem.fieldName,
                operator: currentItem.fieldName !== 'CategoryList' && currentItem.fieldName !== 'Module' && currentItem.fieldName !== 'Application' ? currentItem.notIn ? 'notin' : 'in' : currentItem.notContains ? 'notcontains' : 'contains',
                value: currentItem.value,
              }];
          }

          return accumulator;
        }, []),
        percentage: this.dataComputing
      };
    },
    isPredefined() {
      return this.$store.getters['reporter/adminReportDetails'].predefined;
    },
  },
  watch: {
    reportFrom(reportFrom) {
      const parent = this.$store.getters['reporter/reportOptions']
        .find((item) => item.original === reportFrom);

      if (!parent) return;

      const isGroupedByValid = parent.options.grouping
        .some((item) => item.value === this.groupedBy);

      if (!isGroupedByValid) {
        this.groupedByInit();
      }

      const isSubgroupedByValid = parent.options.grouping
        .some((item) => item.value === this.subgroupedBy);

      if (this.subgroupedBy && !isSubgroupedByValid) {
        this.subgroupedByInit();
      }

      const isTypeValid = parent.options.showing
        .some((item) => item.value === this.type);

      if (!isTypeValid) {
        this.typeInit();
      }

      this.selectionCriteriaInit();
    },
    timeFrame(timeFrame) {
      if (timeFrame === 'lastmonth') {
        if (this.groupedBy === 'Hours') {
          this.groupedByInit();
        }

        if (this.subgroupedBy === 'Hours') {
          this.subgroupedByInit();
        }
      }
    },
  },
  methods: {
    view() {
      this.$store.dispatch('reporter/savePreviewedReportDetails', this.$data);

      this.$router.push({
        path: '/reporter/templates/view',
        query: this.query,
      });
    },
    async save() {
      const isValid = await this.$validator.validate();
      const { GUIModeId, ...query } = this.query;

      if (!isValid) return;

      this.$store.commit('settings/startLoading', true);

      const adminReport = {
        name: this.name,
        type: 'custom',
        guimode_id: this.reportFrom,
        description: this.description,
        query,
        admin_report_i18n: [
          {
            admin_report_description: this.description,
            admin_report_name: this.name,
            language_id: this.$store.getters['settings/getLanguage'] == "es" ? "es-ES" : "en-US"
          }
        ]
      };
      if (this.templateId && !this.isPredefined) {
        await this.$store.dispatch('reporter/updateAdminReport', adminReport);
      } else {
        await this.$store.dispatch('reporter/createAdminReport', adminReport);
      }

      if (this.$store.getters['error']) {
        this.$store.commit('settings/finishLoading', true);
        return;
      }

      setTimeout(() => {
        this.$store.commit('settings/finishLoading', true);
        this.$router.push({ path: '/reporter/templates' });
        this.$notify({
          message: this.t('The report template has been saved'),
          type: 'success',
          verticalAlign: 'bottom',
        });
      }, 1000);
    },
    reportFromInit() {
      if (this.reportFromOptions.length) {
        this.reportFrom = this.reportFromOptions[0].value;
      }
    },
    groupedByInit() {
      if (this.groupedByOptions.length) {
        this.groupedBy = this.groupedByOptions[0].value;
      }
    },
    dataComputingInit() {
      if (this.dataComputingOptions.length) {
        this.dataComputing = this.dataComputingOptions[0].value;
      }
    },
    recordsToShowInit() {
      if (this.recordsToShowOptions.length) {
        this.recordsToShow = this.recordsToShowOptions[0].value;
      }
    },
    typeInit() {
      if (this.typeOptions.length) {
        this.type = this.typeOptions[0].value;
      }
    },
    subgroupedByInit() {
      if (this.subgroupedByOptions.length) {
        this.subgroupedBy = this.subgroupedByOptions[0].value;
      }
    },
    timeFrameInit() {
      if (this.timeFrameOptions.length) {
        this.timeFrame = this.timeFrameOptions[0].value;
      }
    },
    selectionCriteriaInit() {
      let edition = false
      let criteria = this.currentOptionsSet.selecting
       if (this.fixedReportFrom && this.fixedReportFrom === this.reportFrom){
        criteria = this.fixedSelectionCriteria.map( item => ({
          original: item.fieldName,
          label: item.label,
          value: item.value,
          notIn: item.notIn
        }));
        edition = true
      }

      let selectionCriteria = []
      criteria.forEach((item) => {
        if (this.reportFrom === "Web AntiBotnet" && item.original == 'VirusName'){
          selectionCriteria.push({ fieldName: item.original,
          label: 'Threat',
          value: edition ? item.value : '',
          notIn: item.notIn,
          })
        }
        else{
          if ( !['UserName','UserGroups'].includes(item.original)) {
            selectionCriteria.push({
              fieldName: item.original,
              label: item.original === 'ClientId' && !this.$store.getters['settings/isIOT'] ? 'Device'  : item.label,
              value: edition ? item.value : '',
              notIn: item.notIn,
            })
          } else {
            if (this.$store.getters['settings/isIOT']) {
            selectionCriteria.push({ fieldName: item.original,
              label: item.original === 'UserName' ? 'Device' :  'Group',
              value: edition ? item.value : '',
              notIn: item.notIn,
            })
            }
          }
        }
      })
      
      this.selectionCriteria = selectionCriteria;
    },
  },
  async mounted() {
    await this.$store.dispatch('reporter/getReportConfig');

    if (this.$store.getters['reporter/previewedReportDetails'].reportFrom) {
      Object.assign(this, this.$store.getters['reporter/previewedReportDetails']);
      this.fixedReportFrom = this.reportFrom;
      this.fixedSelectionCriteria = this.selectionCriteria;
      this.$store.dispatch('reporter/clearPreviewReportDetails');
    } else if (this.templateId) {
      await this.$store.dispatch(
        'reporter/getAdminReportDetails',
        this.templateId
      );

      Object.assign(
        this,
        this.$store.getters['reporter/reporterTemplateDetails'](this.$store.getters['settings/getLanguage'])
      );
      this.fixedReportFrom = this.reportFrom;
      this.fixedSelectionCriteria = this.selectionCriteria;

      if (this.isPredefined) {
        this.name = `${this.name.replace(/[^\x00-\x7F]/g, "")}_COPY`;
      }
    } else {
      this.reportFromInit();
      this.groupedByInit();
      this.dataComputingInit();
      this.recordsToShowInit();
      this.typeInit();
      this.subgroupedByInit();
      this.timeFrameInit();

      this.selectionCriteriaInit();
    }
  },
};
</script>

<style lang="scss" scoped>
.form-group-switch {
  padding-top: 23px;

  @media (max-width: 767px) {
    padding-top: 11.5px;
  }
}

.switch-label {
  display: inline-block;
  width: 260px;
}

.selection-criteria-toggle {
  display: flex;
  justify-content: flex-end;
  text-align: right;

  .switch-label {
    width: unset;
    margin-right: 1em;
  }
}

.criteria-label {
  padding-bottom: 0 !important;
}

.selection-criteria {
  column-count: 2;
  column-gap: 30px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    height: unset !important;
    column-count: unset;
    margin-bottom: 0;
  }

  @media (min-width: 1200px) and (max-width: 1500px) {
    height: unset !important;
    column-count: unset;
    margin-bottom: 0;
  }
}

.selection-criteria-fg {
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside:avoid;
  -moz-page-break-inside:avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
}
</style>
