<template>
    <div>
        <bar-chart
            v-if="canShowChart('bar')"
            :chart-data="chartData"
            :options="options"
        >
        </bar-chart>
        <line-chart
            v-if="canShowChart('line')"
            :chart-data="chartData"
            :options="options"
        >
        </line-chart>
        <pie-chart
            v-if="canShowChart('pie')"
            :chart-data="chartData"
            :options="options"
        >
        </pie-chart>
        <doughnut-chart
            v-if="canShowChart('doughnut')"
            :chart-data="chartData"
            :options="options"
        >
        </doughnut-chart>
    </div>
</template>

<script>
import BarChart from './BarChart'
import LineChart from './LineChart'
import PieChart from './PieChart'
import DoughnutChart from './DoughnutChart'

export default {
    name: 'vue-charts',
    props: {
        format:{
            type: String,
            default: 'bar'
        },
        chartData: {
            type: Object,
            required: true
        },
        options: {
            type: Object,
            required: true
        },
    },
    components:{
        BarChart,
        LineChart,
        PieChart,
        DoughnutChart
    },
    methods:{
        canShowChart(chart){
            return chart === this.format
        }
    }
}
</script>