<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    >
      <template #button>
        <n-button
          @click.native="handleNew"
          type="primary"
          size="md"
          round
          block
          :visible_for_permissions='[{actions: ["POST"], path: "/reports/.*"}]'
        >
          <i slot="label" >
            <svgicon class="icon" icon="icon-new" />
          </i>
          {{t('New')}}
        </n-button>
      </template>
    </page-title-card>
    <b-card no-body>
      <b-card-body style="padding: 0px;">
        <paginated-table
          :rows='templates'
          :columns='columns'
          :actions="actions"
          :search="['name']"
          :updating="updating"
          :buttonDisabled="buttonDisabled.bind(this)"
          @item-edit="handleEdit"
          @item-remove="handleRemove"
          @item-view="handleView"
          @item-schedule="handleSchedule"
          @item-export="showExportModal"
        />
      </b-card-body>

      <b-modal
        id="export-modal"
        ref="export-modal"
        size='sm'
        :title="t('modal_title')"
        @hidden='resetDownloadReportParams'
        @export='handleExport'
      >
        <b-button
          class='export-modal-button'
          variant="primary"
          size="md"
          block
          @click="$refs['export-modal'].$emit('export', 'csv')">
          {{t('CSV')}}
        </b-button>
        <b-button
          class='export-modal-button mt-3'
          variant="primary"
          size="md"
          block
          @click="$refs['export-modal'].$emit('export', 'pdf')">
          {{t('PDF')}}
        </b-button>
        <template v-slot:modal-footer>
          <b-button
            size="sm"
            variant="default"
            @click="$refs['export-modal'].hide()">
            {{t('Cancel')}}
          </b-button>
        </template>
      </b-modal>
      <template v-slot:footer>
        <div class="hr-grey mb-2"/>
        <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
          <i class="now-ui-icons arrows-1_refresh-69"></i>
          <span>{{t('Update now')}}</span>
        </div>
        <div v-else>
          <i class="now-ui-icons loader_refresh spin"></i>
          <span>{{t('Updating...')}}</span>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { PaginatedTable } from '@/components';
import { YesNoIconFormatter } from '@/components/Tables/Formatters';
import PageTitleCard from "../../components/AsmConfigurations/PageTitleCard";

export default {
  name: 'reporterTemplates',
  components: {
    PaginatedTable,
    PageTitleCard
  },
  data() {
    return {
      reporterTemplates: [],
      updating: false,
      downloadReportParams: {},
      columns: [
        {
          prop: 'name',
          label: this.t('Name'),
          minWidth: 150
        },
        {
          prop: 'type',
          label: this.t('Type'),
          minWidth: 150,
          translateCells: true
        },
        {
          prop: 'programmed',
          label: this.t('Programmed'),
          minWidth: 150,
          formatter: YesNoIconFormatter,
        },
        {
          prop: 'description',
          label: this.t('Description'),
          minWidth: 300,
        }
      ],
      actions: {
        minWidth: 210,
        label: this.t('Actions'),
        fixed: 'right',
        items: [
          {
            type: 'warning',
            icon: 'now-ui-icons business_badge',
            event: 'item-edit',
            action: this.t('Edit'),
            visible_for_permissions: [{actions: ["POST"], path: "/reports/.*"}]
          },
          {
            type: 'danger',
            size: 'sm',
            icon: 'now-ui-icons ui-1_simple-remove',
            event: 'item-remove',
            action: this.t('Remove'),
            confirm: true,
            message: this.t('confirmation'),
            visible_for_permissions: [{actions: ["DELETE"], path: "/reports/.*"}]
          },
          {
            type: 'success',
            size: 'sm',
            icon: 'view-button',
            isSVGIcon: true,
            event: 'item-view',
            action: this.t('View'),
            visible_for_permissions: [{actions: ["POST"], path: "/reports/.*"}]
          },
          {
            type: 'info',
            size: 'sm',
            icon: 'calendar',
            isSVGIcon: true,
            isIconOriginal: true,
            event: 'item-schedule',
            action: this.t('Schedule'),
            visible_for_permissions: [{actions: ["PATCH"], path: "/reports/.*"}]
          },
          {
            type: 'default',
            size: 'sm',
            icon: 'now-ui-icons arrows-1_cloud-download-93',
            event: 'item-export',
            action: this.t('Export'),
            visible_for_permissions: [{actions: ["POST"], path: "/reports/.*"}]
          },
        ],
      },
    };
  },
  computed: {
    templates(){
      return this.$store.getters['reporter/reporterTemplates'](this.$store.getters['settings/getLanguage']);
    }
  },
  methods: {
    handleNew(){
      this.$router.push({ path: '/reporter/templates/new' });
    },
    handleEdit(data){
      this.$router.push({ path: `/reporter/templates/edit/${encodeURIComponent(data.row.id)}` });
    },
    handleRemove(data){
      this.$store.dispatch('reporter/deleteAdminReport', data.row.id);
    },
    handleView(data) {
      const query = {
        name: data.row.name,
        GUIModeId: data.row.GUIModeId,
        ...data.row.query,
      }

      this.$router.push({ path: '/reporter/templates/view', query });
    },
    handleSchedule(data) {
      this.$router.push({ path: `/reporter/templates/schedule/${encodeURIComponent(data.row.id)}` });
    },
    showExportModal(data) {
      let translations = {}
      if ( data.row.GUIModeId === 'Web AntiBotnet')
        translations['VirusName'] = 'Threat'
      if ( this.$store.getters['settings/isIOT']){
        translations['UserName'] = 'Device'
        translations['UserGroups'] = 'Group'
        translations['ClientId'] = 'Account'
      }
      else{
        translations['ClientId'] = 'Device'
      }
      this.downloadReportParams = {
        GUIModeId: data.row.GUIModeId,
        query: {...data.row.query, translations},
        name: data.row.name
      };

      this.$refs['export-modal'].show();
    },
    async handleExport(type) {
      this.$refs['export-modal'].hide();
      const payload = {
        query: this.downloadReportParams.query,
        GUIModeId: this.downloadReportParams.GUIModeId,
        name: this.downloadReportParams.name,
        type
      };

      await this.$store.dispatch('reporter/downloadReportType', payload)
      .then({})
      .catch( err => {
        if (err.status === 409)
          this.$store.commit('setError', this.t("REPORTS.EXPORT_EMPTY_REPORT"))
        else
          this.$store.commit('setError', err)
        });

      this.resetDownloadReportParams();
    },
    resetDownloadReportParams() {
      this.downloadReportParams = {};
    },
    async refresh(wait) {
      this.updating = true;
      await this.$store.dispatch('reporter/getAdminReportList');
      this.updating = false;
    },
    buttonDisabled(row, event) {
      // ASM-2116 - Only custom reports can be removed. For other reports the Remove button will be disabled
      let res;
      (row.type == 'custom' && event == 'item-remove' || event != 'item-remove') ? res = false : res = true;
      return res;
    }
  },
  async mounted() {
    this.updating = true;
    await this.$store.dispatch('reporter/getReportConfig');

    this.refresh(0);
  },
}
</script>
<style lang="scss" scoped>
  .export-modal-button {
    border-radius: 30px;
  }
</style>

