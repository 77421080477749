<template>
  <b-container fluid class="p-0 reporter-templates-view">
    <page-head-actions :title="name">
      <template slot="buttons">
        <b-button
          variant="primary"
          class="btn-round pull-right"
          @click="$router.go(-1)"
          >{{ t("Back") }}</b-button
        >
      </template>
    </page-head-actions>

    <b-card>
      <template v-slot:header>
        <h4>
          <i class="tim-icons icon-image-02"></i>
          <span>{{ t("Report view") }}</span>
        </h4>
      </template>
      <b-row>
        <b-col cols="12" md="9" xl="8">
          <div v-if="getReportViewError">
            {{ getReportViewError }}
          </div>
          <div v-else class="reporter-templates-view__chart-wrapper">
            <vue-charts
              v-if="reportFormat !== 'table'"
              :format="reportFormat"
              :chartData="chartData"
              :options="chartOptions"
            >
            </vue-charts>
            <b-table
              v-if="reportFormat === 'table'"
              class="reporter-templates-view__cell-align-baseline"
              striped
              bordered
              :fields="tableFields"
              :items="tableItems"
              head-row-variant="light"
              head-variant="light"
              fixed
              hover
            >
              <template v-slot:cell(name)="data" class="ewrewr">
                <div
                  :class="
                    data.item.subgroup
                      ? 'reporter-templates-view__cell-row_bg-light-orange'
                      : ''
                  "
                >
                  <b>
                    {{ data.item.name }}
                  </b>
                </div>
                <div
                  class="reporter-templates-view__cell-row_subgroup"
                  v-for="(item, index) in data.item.subgroup || []"
                  :key="`cell-subgroup-name-${index}`"
                >
                  {{ item.name }}
                </div>
              </template>
              <template v-slot:cell(value)="data" class="ewrewr">
                <div
                  class="reporter-templates-view__cell-row"
                  :class="
                    data.item.subgroup
                      ? 'reporter-templates-view__cell-row_bg-light-orange'
                      : ''
                  "
                >
                  <b>
                    {{ data.item.subgroup ? "Total: " : "" }}
                    {{ data.item.value }}
                  </b>
                </div>
                <div
                  class="reporter-templates-view__cell-row_subgroup"
                  v-for="(item, index) in data.item.subgroup || []"
                  :key="`cell-subgroup-value-${index}`"
                >
                  {{ item.value }}
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
        <b-col class="mt-2 mt-lg-0" cols="12" md="3" xl="2" offset-xl="1">
          <b-row>
            <b-col cols="12">
              <b-form-group class="form-group-select" :label="t('Format:')">
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="----"
                  v-model="reportFormat"
                >
                  <el-option
                    v-for="(option, index) in reportFormatOptions"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="`report-from-option_${index}`"
                  >
                  </el-option>
                </el-select>
              </b-form-group>
              <div v-if="!isHistoric">
                <legend class="bv-no-focus-ring col-form-label pb-0">
                  {{ t("Export") }}:
                </legend>
                <n-button
                  class="mr-2"
                  type="primary"
                  size="md"
                  round
                  block
                  @click.native="handleExport('csv')"
                  :disabled="!$store.getters['reporter/reportView'].data.length"
                  >CSV</n-button
                >
                <n-button
                  class="mt-2"
                  type="primary"
                  size="md"
                  round
                  block
                  @click.native="handleExport('pdf')"
                  :disabled="!$store.getters['reporter/reportView'].data.length"
                  >PDF</n-button
                >
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import PageHeadActions from "@/components/PageHeadActions";
import VueCharts from "@/components/VueCharts/VueCharts";
import { Select, Option } from "element-ui";

export default {
  name: "ReporterTemplatesView",
  components: {
    PageHeadActions,
    VueCharts,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    GUIModeId: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    group: {
      type: Array,
      default() {
        return [];
      },
    },
    limit: {
      type: Number,
      default: 0,
    },
    order: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      default: "eng",
    },
    where: {
      type: Array,
      default() {
        return [];
      },
    },
    date_interval: {
      type: [String, Object],
      default: "",
    },
    isHistoric: {
      type: Boolean,
      default: false,
    },
    adminReportId: {
      type: String,
      default: "",
    },
    reportName: {
      type: String,
      default: "",
    },
    percentage: {
      type: Boolean,
      default: false,
    },
    graph_style: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      colors: [
        "#42a5f5",
        "#9fa8da",
        "#c5e1a5",
        "#ffcc80",
        "#ffab91",
        "#fff59d",
        "#29b6f6",
        "#7e57c2",
        "#66bb6a",
        "#90caf9",
        "#80deea",
        "#a5d6a7",
        "#ffca28",
        "#ffee58",
        "#b0bec5",
        "#e6ee9c",
        "#5c6bc0",
        "#ef9a9a",
        "#ce93d8",
        "#9ccc65",
        "#80cbc4",
        "#78909c",
        "#81d4fa",
        "#26c6da",
        "#ffa726",
        "#d4e157",
        "#b39ddb",
        "#ff7043",
        "#ffe082",
        "#f48fb1",
        "#ef5350",
        "#ec407a",
        "#bcaaa4",
        "#8d6e63",
        "#ab47bc",
        "#26a69a",
        "#F06292",
        "#A5D6A7",
        "#3F51B5",
        "#B39DDB",
        "#D81B60",
        "#F8BBD0",
        "#EF9A9A",
        "#64FFDA",
        "#8BC34A",
        "#B388FF",
        "#D4E157",
        "#00BCD4",
        "#26C6DA",
        "#651FFF",
      ],
      reportFormat: "bar",
      getReportViewError: "",
    };
  },
  computed: {
    groupby() {
      return this.group.map((item) => {
        switch (item) {
          case "VirusName":
            return this.GUIModeId == "Web AntiBotnet" ? "Threat" : item;
          case "ClientId":
            return this.$store.getters['settings/isIOT'] ? "Account" : "Device";
          case "UserName":
            return this.$store.getters['settings/isIOT'] ? "Device" : item;
          case "UserGroups":
            return this.$store.getters['settings/isIOT'] ? "Group" : item;
          default:
            return item;
        }
      });
    },
    isSingleLevelReport() {
      return (
        this.$store.getters["reporter/reportView"].report_type ===
        "aggregated_1_level"
      );
    },
    reportFormatOptions() {
      return [
        { label: this.t("Bar chart"), value: "bar" },
        { label: this.t("Line chart"), value: "line" },
        { label: this.t("Pie chart"), value: "pie" },
        { label: this.t("Table"), value: "table" },
      ];
    },
    chartOptions() {
      let gridLinesColor = this.$store.state.settings.darkmode
        ? "rgba(255, 255, 255, 0.2)"
        : "rgba(0, 0, 0, 0.1)";
      let labelsColor = this.$store.state.settings.darkmode
        ? "rgba(255, 255, 255, 0.7)"
        : "#666";

      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: this.reportFormat === "pie" || !this.isSingleLevelReport,
          labels: {
            fontColor: labelsColor,
          },
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: { fontColor: labelsColor, beginAtZero: true },
              ...(this.reportFormat === "pie" && { display: false }),
              gridLines: {
                color: gridLinesColor,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              ticks: { fontColor: labelsColor, beginAtZero: true },
              ...(this.reportFormat === "pie" && { display: false }),
              gridLines: {
                color: gridLinesColor,
              },
            },
          ],
        },
        elements: {
          line: {
            tension: 0,
            fill: false,
          },
        },
      };
    },
    chartData() {
      let datasets;
      let labels;

      switch (this.reportFormat) {
        case "bar":
          datasets = this.getBarDatasets();
          labels = this.getBarLabels();
          break;
        case "line":
          datasets = this.getLineDatasets();
          labels = this.getLineLabels();
          break;
        case "pie":
          datasets = this.getPieDatasets();
          labels = this.getPieLabels();
          break;
        default:
          datasets = this.$store.getters["reporter/chartData"].datasets;
          labels = this.$store.getters["reporter/chartData"].labels;
      }

      return {
        labels,
        datasets,
        fullName: this.$store.getters["reporter/chartData"].labels,
      };
    },
    reportView() {
      return this.$store.getters["reporter/reportView"];
    },
    tableFields() {
      return [
        { key: "name", label: this.groupby.join(" / ") },
        { key: "value", label: this.type },
      ];
    },
    tableItems() {
      if (this.isSingleLevelReport) {
        return this.chartData.fullName.map((item, index) => ({
          name: item,
          value: this.chartData.datasets[0].data[index],
        }));
      }

      return this.reportView.data.map((item) => {
        return {
          name: item.name,
          value: item.total,
          subgroup: [...item.value],
        };
      });
    },
  },
  methods: {
    getBarDatasets() {
      return this.$store.getters["reporter/chartData"].datasets
        .slice(0, 50)
        .map((item, index) => {
          let data = item.data.slice(0, 50);
          let label =
            item.label && item.label.length > 10
              ? `${item.label.slice(0, 7)}...`
              : item.label;
          return {
            ...item,
            data: data,
            label: label,
            backgroundColor: this.colors[index],
          };
        });
    },
    getBarLabels() {
      return this.$store.getters["reporter/chartData"].chartLabels.slice(0, 50);
    },
    getLineDatasets() {
      return this.$store.getters["reporter/chartData"].datasets
        .slice(0, 50)
        .map((item, index) => {
          let data = item.data.slice(0, 50);
          let label =
            item.label && item.label.length > 10
              ? `${item.label.slice(0, 7)}...`
              : item.label;
          return {
            ...item,
            data: data,
            label: label,
            borderColor: this.colors[index],
            pointRadius: 5,
            pointHoverRadius: 5,
          };
        });
    },
    getLineLabels() {
      return this.$store.getters["reporter/chartData"].chartLabels.slice(0, 50);
    },
    getPieDatasets() {
      if (this.isSingleLevelReport) {
        return this.$store.getters["reporter/chartData"].datasets
          .slice(0, 20)
          .map((item, index) => {
            let data = item.data.slice(0, 20);
            let label =
              item.label && item.label.length > 10
                ? `${item.label.slice(0, 7)}...`
                : item.label;
            return {
              ...item,
              data: data,
              label: label,
              backgroundColor: this.colors,
            };
          });
      }

      return this.$store.getters["reporter/chartData"].chartLabels.map(
        (label, index) => ({
          label: label,
          backgroundColor: this.colors,
          data: this.$store.getters["reporter/chartData"].datasets
            .slice(0, 20)
            .map((item) => item.data[index]),
        })
      );
    },
    getPieLabels() {
      if (this.isSingleLevelReport) {
        return this.$store.getters["reporter/chartData"].chartLabels.slice(
          0,
          20
        );
      }

      return this.$store.getters["reporter/chartData"].datasets
        .map((item) => item.label)
        .slice(0, 20);
    },
    async handleExport(type) {
      let translations = {};
      if (this.GUIModeId === "Web AntiBotnet")
        translations["VirusName"] = "Threat";
      if (this.$store.getters['settings/isIOT']) {
        translations["UserName"] = "Device";
        translations["UserGroups"] = "Group";
        translations["ClientId"] = "Account";
      } else {
        translations["ClientId"] = "Device";
      }
      const payload = {
        query: {
          type: this.type,
          group: this.group,
          limit: this.limit,
          order: this.order,
          date_interval: this.date_interval,
          lang: this.lang,
          where: this.where,
          percentage: this.percentage,
          style: this.reportFormat,
          translations: translations,
        },
        GUIModeId: this.GUIModeId,
        name: this.name,
        type,
      };

      await this.$store.dispatch("reporter/downloadReport", payload);
    },
  },
  async mounted() {
    this.$store.dispatch("reporter/resetReportView");

    this.$store.commit("settings/startLoading", true);

    if (this.isHistoric) {
      await this.$store.dispatch("reporter/getAdminReportExecutionResults", {
        adminReportId: this.adminReportId,
        reportName: this.reportName,
      });
    } else {
      await this.$store
        .dispatch("reporter/getReportView", {
          GUIModeId: this.GUIModeId,
          type: this.type,
          group: this.group,
          limit: this.limit,
          order: this.order,
          date_interval: this.date_interval,
          lang: this.lang,
          where: this.where,
          percentage: this.percentage,
        })
        .catch((err) => {
          this.getReportViewError = err.message;
        });
    }

    this.reportFormat = this.graph_style;

    setTimeout(() => {
      this.$store.commit("settings/finishLoading", true);
    }, 1000);
  },
};
</script>

<style lang="scss">
.reporter-templates-view {
  &__chart-wrapper {
    max-width: 700px;
    margin: auto;
    padding-bottom: 20px;
  }
  &__buttons-wrapper {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      flex-direction: column;
    }
  }
  &__cell-row {
    &_subgroup {
      font-size: 12px;
    }
  }
  &__cell-align-baseline {
    tbody > tr > td {
      vertical-align: top !important;
    }
  }
}
</style>
