import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Pie,
  name: 'pie-chart',
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.pieOptions)
  },
  computed: {
    pieOptions() {
      return {
        ...this.options,
        tooltips: {
          callbacks: {
            label: function(item, data) {
              return `(${data.datasets[item.datasetIndex].label})  ${data.labels[item.index]}: ${data.datasets[item.datasetIndex].data[item.index]}`;
            }
          }
        }
      }
    }
  },
  watch:{
    options(){
      this.renderChart(this.chartData, this.pieOptions)
    }
  }
}